import { css, SerializedStyles } from '@emotion/react';
import { graphql, useStaticQuery } from 'gatsby';
import {
    GatsbyImage,
    getImage,
    ImageDataLike,
    StaticImage,
} from 'gatsby-plugin-image';
import * as React from 'react';
import useInterval from 'use-interval';
import { ChatBubble } from '../components/layout/visual/chat-bubble';
import { RollingCharacters } from '../components/layout/visual/rolling-characters';
import { DefaultLayout } from '../layouts/default';
import { Container } from '../components/layout/common/container';
import styled from '@emotion/styled';
import blueDitherImage from '../assets/images/dither-blue.png';
import orangeDitherImage from '../assets/images/dither-orange.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import AbsImage from '../components/layout/common/abs-image';
import hangingBoxImage from '../assets/images/hanging-box.png';
import leftManufactureImage from '../assets/images/manufacture-left.png';
import rightManufactureImage from '../assets/images/manufacture-right.png';
import yellowHeartImage from '../assets/images/yellow-heart.png';
import flyingVoxImage from '../assets/images/flying-vox.png';
import tenThousandNFTImage from '../assets/images/10000nft.png';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { match } from 'ts-pattern';

const ImageAsideLogo: React.VFC<{
    x: number;
    y?: number;
    src: string;
    alt: string;
    flip?: boolean;
}> = ({ x, y, src, alt, flip }) => {
    return (
        <img
            css={css`
                position: absolute;

                ${x > 0 ? `left: ${x}px;` : `right: ${-x}px;`}

                bottom: ${y || 0}px;

                ${flip === true ? `transform: scaleX(-1);` : ''}
            `}
            src={src}
            alt={alt}
        ></img>
    );
};

const FadeOutFly: React.FC<{
    id: string;
    delay: number;
    duration: number;
    scale: number;
    x: number;
    y: number;
}> = ({ id, children, delay, duration, scale, x, y }) => {
    return (
        <div
            css={css`
                width: 0;
                height: 0;
                position: relative;
                overflow: visible;

                opacity: 0;

                animation-name: fade-out-${id};
                animation-duration: ${duration}s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-delay: ${delay}s;

                top: ${y}px;
                left: ${x}px;

                @keyframes fade-out-${id} {
                    0% {
                        opacity: 0;
                        transform: scale(${0.6 * scale}) translate(80px);
                    }

                    20% {
                        opacity: 1;
                        transform: scale(${1 * scale}) translateY(0);
                    }

                    50% {
                        opacity: 0;
                        transform: scale(${0.6 * scale}) translateY(-300px);
                    }
                }
            `}
        >
            {children}
        </div>
    );
};

const SectionTitle: React.VFC<{
    image: React.ReactElement;
    label: string;
}> = ({ image, label }) => {
    return (
        <div
            css={css`
                text-align: center;
            `}
        >
            {image}
            <h1
                css={css`
                    font-family: pixel;
                    font-size: 4.1rem;
                    letter-spacing: 4px;

                    margin-top: 15px;
                    margin-bottom: 50px;
                `}
            >
                {label}
            </h1>
        </div>
    );
};

const TitleSection: React.VFC = () => {
    const queryResult = useStaticQuery<{
        logoImage: {
            childImageSharp: {
                gatsbyImageData: ImageDataLike;
            };
        };

        characterImages: {
            nodes: {
                childImageSharp: {
                    fixed: {
                        src: string;
                        width: number;
                        height: number;
                    };
                };
            }[];
        };
    }>(graphql`
        query {
            logoImage: file(relativePath: { eq: "images/logo-light.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        height: 400
                        quality: 100
                        placeholder: NONE
                    )
                }
            }

            characterImages: allFile(
                filter: {
                    relativePath: {
                        in: [
                            "images/vox-character-0.png"
                            "images/vox-character-1.png"
                            "images/vox-character-2.png"
                        ]
                    }
                }
                sort: { fields: [relativePath] }
            ) {
                nodes {
                    childImageSharp {
                        fixed(width: 200) {
                            src
                            width
                            height
                        }
                    }
                }
            }
        }
    `);

    const logoImage = getImage(
        queryResult.logoImage.childImageSharp.gatsbyImageData
    );

    const characterImages = queryResult.characterImages.nodes.map(
        (node) => node.childImageSharp.fixed
    );

    const xoxoTexts = ['X', 'XO', 'XOX', 'XOXO', 'XOX', 'XO'];
    const [xoxoIndex, setXoxoIndex] = React.useState(0);
    const xoxoText = React.useMemo(() => xoxoTexts[xoxoIndex], [xoxoIndex]);

    useInterval(() => {
        setXoxoIndex((xoxoIndex + 1) % xoxoTexts.length);
    }, 500);

    const breakpoints = useBreakpoint();

    const drawCharacters = match(breakpoints)
        .with({ md: true }, () => false)
        .otherwise(() => true);

    const characterPositionRatio = match(breakpoints)
        .with({ l: true }, () => 0.4)
        .otherwise(() => 1);

    return (
        <section
            id="title"
            css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                height: 800px;

                background-color: #abfffa;
                padding: 0 20px;
            `}
        >
            {/* Fade in wrapper, center aligning */}
            <div
                css={css`
                    animation-name: fade-in;
                    animation-duration: 1s;
                    animation-timing-function: ease-in-out;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    @keyframes fade-in {
                        0% {
                            opacity: 0;
                            transform: translateY(20px);
                        }

                        100% {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }
                `}
            >
                {/* Title logo */}
                {logoImage && (
                    <GatsbyImage image={logoImage} alt="logo"></GatsbyImage>
                )}

                {/* Subtitle */}
                <div
                    css={css`
                        font-family: pixel;
                        font-size: 3.5rem;
                        margin-top: 30px;

                        animation-name: subtitle-rotate;
                        animation-duration: 2s;
                        animation-iteration-count: infinite;
                        animation-timing-function: ease-in-out;
                        animation-fill-mode: both;
                        white-space: nowrap;

                        @keyframes subtitle-rotate {
                            0% {
                                transform: rotateZ(4deg);
                            }

                            50% {
                                transform: rotateZ(-4deg);
                            }

                            100% {
                                transform: rotateZ(4deg);
                            }
                        }
                    `}
                >
                    WORLD OF ADENA VOX!!
                </div>

                {/* Button to adena game */}
                <button
                    css={css`
                        margin-top: 30px;
                        padding: 25px 40px;
                        background: #fff;
                        border-radius: 48px;
                        font-family: pixel;
                        font-size: 3rem;
                        color: #00aca2;
                        border: 0;
                        box-shadow: 0px 0px 33px rgba(0, 0, 0, 0.1);
                        cursor: pointer;
                    `}
                >
                    THE-ADENA GAME
                </button>

                {/* Character images */}
                {drawCharacters && (
                    <div
                        css={css`
                            position: relative;
                            white-space: nowrap;
                            overflow: visible;
                        `}
                    >
                        {characterImages[0] && (
                            <ImageAsideLogo
                                src={characterImages[0].src}
                                alt="Vox Character 1"
                                x={
                                    -(logoImage?.width ?? 0) / 2 -
                                    100 * characterPositionRatio
                                }
                                y={0}
                            ></ImageAsideLogo>
                        )}
                        {characterImages[1] && (
                            <ImageAsideLogo
                                src={characterImages[1].src}
                                alt="Vox Character 2"
                                x={
                                    -(logoImage?.width ?? 0) / 2 -
                                    100 * characterPositionRatio -
                                    125
                                }
                                y={0}
                            ></ImageAsideLogo>
                        )}
                        {characterImages[2] && (
                            <ImageAsideLogo
                                src={characterImages[2].src}
                                alt="Vox Character 3"
                                x={
                                    (logoImage?.width ?? 0) / 2 +
                                    100 * characterPositionRatio
                                }
                                y={0}
                                flip
                            ></ImageAsideLogo>
                        )}
                        {/* Right chat bubble */}
                        <ChatBubble
                            type="large"
                            scale={0.4}
                            x={
                                (logoImage?.width ?? 0) / 2 +
                                (characterImages[2]?.width ?? 0) / 2 +
                                80 +
                                100 * characterPositionRatio
                            }
                            y={(characterImages[2]?.height ?? 0) / 2 + 160}
                        >
                            {xoxoText}
                        </ChatBubble>
                        {/* Left chat bubbles with animation */}
                        <FadeOutFly
                            id="1"
                            delay={0.2}
                            duration={3.4}
                            scale={0.6}
                            x={
                                -(logoImage?.width ?? 0) / 2 -
                                300 -
                                100 * characterPositionRatio
                            }
                            y={(-characterImages[0].height ?? 0) + 50}
                        >
                            <ChatBubble type="small">:P</ChatBubble>
                        </FadeOutFly>{' '}
                        <FadeOutFly
                            id="2"
                            delay={1.4}
                            duration={6.2}
                            scale={0.4}
                            x={
                                -(logoImage?.width ?? 0) / 2 -
                                280 -
                                100 * characterPositionRatio
                            }
                            y={-characterImages[0].height ?? 0}
                        >
                            <ChatBubble type="small">^^</ChatBubble>
                        </FadeOutFly>
                        <FadeOutFly
                            id="3"
                            delay={0.5}
                            duration={4.8}
                            scale={0.3}
                            x={
                                -(logoImage?.width ?? 0) / 2 -
                                260 -
                                100 * characterPositionRatio
                            }
                            y={(-characterImages[0].height ?? 0) + 20}
                        >
                            <ChatBubble type="small">
                                <StaticImage
                                    src="../assets/images/red-heart.png"
                                    alt="Love"
                                    width={60}
                                    quality={100}
                                ></StaticImage>
                            </ChatBubble>
                        </FadeOutFly>
                    </div>
                )}
            </div>
        </section>
    );
};

const StorySection: React.VFC = () => {
    const breakpoints = useBreakpoint();

    const paddingX = match(breakpoints)
        .with({ xs: true }, () => 10)
        .with({ sm: true }, () => 20)
        .with({ md: true }, () => 50)
        .otherwise(() => 80);

    return (
        <section
            id="story"
            css={css`
                background-color: #abfffa;
                padding: 160px 0;
            `}
        >
            <Container>
                <div
                    css={css`
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding: 60px ${paddingX}px 40px ${paddingX}px;
                        text-align: center;

                        background-color: #fff;
                        border-radius: 24px;

                        filter: drop-shadow(0px 4px 120px rgba(0, 0, 0, 0.1));
                    `}
                >
                    <SectionTitle
                        image={
                            <StaticImage
                                src="../assets/images/book.png"
                                alt="book"
                                width={70}
                                placeholder={'none'}
                                quality={100}
                            />
                        }
                        label="STORY"
                    />
                    <p
                        css={css`
                            line-height: 1.6;
                        `}
                    >
                        귀여운 인형같고 레고같이 생긴 이 아데나VOX는
                        패션피플이자 여행다니기를 매우 좋아해요.
                        <br />
                        크기도 매우 작은데 한 20cm?.. 그런데 이들은 아주 독특한
                        능력을 지니고 있지요. <br />
                        세계 곳곳을 돌아다니기에는 위험할 수도 있으니까요.
                        그래서 이들은, 주인을 따라다니며 돌아다녀야 해요. <br />
                        마치 강아지가 주인을 따라 산책하는것처럼 말이죠. 한번
                        주인을 만나면 귀찮도록 옆에 따라다녀요. <br />
                        때때론, 너무 귀찮아서 때리고 싶을정도에요.
                        <br />
                        하지만, VOX들은 주인에게 신비로운 능력을 부여해줘요.
                        여러분이 만약, 위험한 존재를 <br />
                        마주쳤을때, 꼭 무언가를 이루어내야 할때, 이겨내야할때.
                        바로 주인에게 특별한 힘을 주지요.
                        <br />
                        이 능력때문에 모험을 좋아하는 사람들은 '나'를 주인으로
                        섬길 VOX를 찾곤 해요. <br />
                        이들을 어디서 만날지는 여러분이 여행을 떠나면서 만나야
                        해요. 열려있는 바다? ...라든지..
                        <br />
                        <br />
                        나만의 VOX를 찾았다면, PFP로 내 옆에 두세요. 여러분을
                        지켜줄꺼에요.
                        <br />
                        뿐만아니라, 앞으로 P2E 라 불리는 드넓은 게임 안에서
                        주인이나 유저들을 서포트해주는 <br />
                        역할을 하게 될 거에요. 평상시 PFP로 사용했던 귀여운
                        VOX가 더 넓은 세상으로 나가
                        <br />
                        여러분을 도울 수 있게 된다면? 잠깐, 꺼내서 도움을
                        청하세요.
                        <br />
                        <br />
                        특히, 아데나게임뿐만 아니라 앞으로 나오게 될 귀여운 P2E
                        2D 게임, 파트너십 등을 통해 포털을 거쳐
                        <br />
                        다른게임에서도 여러분들을 서포트해줄 수 있게 될 거에요.
                        복스는 넓은 세계에서
                        <br />
                        모두를 서포트해줄 수 있는 능력을 지니고 있습니다. 앞으로
                        클레이튼 네트워크 뿐만 아니라
                        <br />
                        사이드체인으로 더 넓은세상에서 만나게 될지도 몰라요.
                        <br />
                        <br />
                        귀엽게 생겼지만, 이들은 아주 독특한 능력을 지니고
                        있습니다.
                        <br />
                        여행을 좋아하는 복스들은 주인을 만나 이곳저곳을 다니게
                        될 것입니다.
                        <br />
                        이 복스들은 여러분을 옆에서 든든히 지켜줄 겁니다. <br />
                        때로는 애교많은 강아지, 때로는 친구처럼...
                        <br />
                        <br />
                        <strong>
                            거버넌스의 역할을 똑똑히 해줄 아데나VOX를 만날 수
                            있도록 힘을 주세요!!
                        </strong>
                    </p>
                    <div
                        css={css`
                            color: #1ef1e5;
                            font-weight: 700;
                            font-size: 1.5rem;
                            margin-top: 50px;
                        `}
                    >
                        Coming Soon!
                    </div>
                    <button
                        css={css`
                            font-family: pixel;
                            font-size: 4rem;
                            color: #fff;
                            background-color: #1ef1e5;
                            padding: 15px 50px;
                            border-radius: 48px;
                            box-shadow: 0px 0px 33px rgba(0, 0, 0, 0.1);
                            border: none;
                            margin-top: 15px;
                        `}
                    >
                        MINTING
                    </button>
                </div>
            </Container>
        </section>
    );
};

const RoadmapItem: React.FC<{
    number?: number;
    color?: string;
    label?: string;
    baseStyles?: SerializedStyles;
}> = ({ number, color, label, children, baseStyles }) => {
    const breakpoints = useBreakpoint();
    const scale = match(breakpoints)
        .with(
            {
                xs: true,
            },
            () => 0.2
        )
        .with(
            {
                sm: true,
            },
            () => 0.4
        )
        .with(
            {
                md: true,
            },
            () => 0.6
        )
        .otherwise(() => 1);

    return (
        <div
            css={css`
                padding: ${40 * scale}px ${60 * scale}px;
                background-color: #fff;
                border-radius: 24px;
                filter: drop-shadow(0px 4px 120px rgba(0, 0, 0, 0.1));

                height: 350px;

                ${baseStyles};
            `}
        >
            {number && (
                <h2
                    css={css`
                        font-family: pixel;
                        font-size: 4.5rem;
                        color: ${color};
                        margin-bottom: 30px;
                        font-weight: 400;
                    `}
                >
                    {number}
                </h2>
            )}
            {label && (
                <h3
                    css={css`
                        font-size: 1.5rem;
                        color: #000;
                        font-weight: 700;
                        margin-bottom: 40px;
                    `}
                >
                    {label}
                </h3>
            )}

            <div
                css={css`
                    font-size: 1.25rem;
                    color: #000;
                    font-weight: 400;
                    line-height: 30px;
                `}
            >
                {children}
            </div>
        </div>
    );
};

const RoadmapItemWrapper = styled.div<{ scale: number }>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    row-gap: ${({ scale }) => 60 * scale}px;
    flex: 1;
`;

const Dither: React.VFC<{
    image: string;
    color: string;
    direction: 'top' | 'bottom';
    margin?: number;
}> = ({ image, color, direction, margin }) => {
    return (
        <div
            css={css`
                position: relative;
                width: 100%;
                height: 0;
                overflow: visible;

                z-index: -1;
            `}
        >
            {direction === 'bottom' && (
                <div
                    css={css`
                        background-color: ${color};
                        width: 100%;
                        height: ${margin ?? 0}px;
                    `}
                ></div>
            )}
            <div
                css={css`
                    padding: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 0;
                `}
            >
                <img
                    css={css`
                        width: 100%;
                        transform: translateY(-50%);
                    `}
                    src={image}
                    alt="dither"
                />
            </div>
            {direction === 'top' && (
                <div
                    css={css`
                        background-color: ${color};
                        width: 100%;
                        height: ${margin ?? 0}px;
                    `}
                ></div>
            )}
        </div>
    );
};

const RoadmapSection: React.VFC = () => {
    const breakpoints = useBreakpoint();
    const scale = match(breakpoints)
        .with(
            {
                xs: true,
            },
            () => 0.1
        )
        .with(
            {
                sm: true,
            },
            () => 0.2
        )
        .with(
            {
                md: true,
            },
            () => 0.5
        )
        .otherwise(() => 1);

    const phases: (
        | {
              type: 'phase';
              number: number;
              color: string;
              label: string;
              contents: string;
          }
        | { type: 'loading' }
        | { type: 'notice' }
    )[] = [
        {
            type: 'phase',
            number: 1,
            color: '#3d73f9',
            label: 'PHASE 1',
            contents: `- 아데나복스 홈페이지 업데이트
- 아데나복스 밈 컨테스트
- 아데나복스 10,000 MINT
- 유튜브, 블로그 등 인플루언서 마케팅
- NFT 저작권포함 소유권이전 공식 서류 게시`,
        },
        {
            type: 'phase',
            number: 2,
            color: '#f889fc',
            label: 'PHASE 2',
            contents: `- PFP 아데나복스 자체 대대적 광고
- PFP 아데나복스 인형제작 및 선물이벤트
- NFT 프로젝트 포털 파트너십 및 AMA
- 아데나복스 NFT 생태계지도(포털)`,
        },
        {
            type: 'phase',
            number: 3,
            color: '#fd8240',
            label: 'PHASE 3',
            contents: `- P2E 게임 ADENA 마이닝맵 공개
- ADENA TOKEN IEO 및 토큰노믹스 공개
- P2E 게임 ADENA 오토헌팅맵 공개
- 아데나 P2E 대대적 광고`,
        },
        {
            type: 'phase',
            number: 4,
            color: '#ffd459',
            label: 'PHASE 4',
            contents: `- 글로벌화위한 로드맵 재설계
- 개발인력 보강
- 국내 게임회사 파트너십
- 아데나 자체 네트워크 개발착수`,
        },
        { type: 'loading' },
        { type: 'notice' },
    ];

    const splitPhases = match(breakpoints)
        .with({ sm: true }, () => [
            [phases[0], phases[1], phases[2], phases[3], phases[4], phases[5]],
        ])
        .otherwise(() => [
            [phases[0], phases[2], phases[4], phases[5]],
            [phases[1], phases[3]],
        ]);

    return (
        <section id="roadmap">
            <Dither
                image={blueDitherImage as string}
                color="#abfffa"
                direction="bottom"
                margin={200}
            />
            <Container
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                `}
            >
                <SectionTitle
                    image={
                        <StaticImage
                            src="../assets/images/check.png"
                            alt="book"
                            width={70}
                            placeholder={'none'}
                            quality={100}
                        />
                    }
                    label="ROADMAP"
                />
                <div
                    css={css`
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        column-gap: ${120 * scale}px;
                        z-index: 1;
                    `}
                >
                    {splitPhases.map((phases, i) => (
                        <RoadmapItemWrapper
                            key={`roadmap-phase-${i}`}
                            scale={scale}
                        >
                            {phases.map((phase, j) =>
                                match(phase)
                                    .with({ type: 'phase' }, (e) => (
                                        <RoadmapItem
                                            key={`roadmap-phase-${i}-${j}`}
                                            color={e.color}
                                            number={e.number}
                                            label={e.label}
                                        >
                                            {e.contents
                                                .split('\n')
                                                .map((e, k) => (
                                                    <React.Fragment
                                                        key={`roadmap-phase-${i}-${j}-${k}`}
                                                    >
                                                        {e} <br />
                                                    </React.Fragment>
                                                ))}
                                        </RoadmapItem>
                                    ))
                                    .with(
                                        {
                                            type: 'loading',
                                        },
                                        () => (
                                            <RoadmapItem
                                                key={`roadmap-phase-${i}-${j}`}
                                                baseStyles={css`
                                                    display: flex;
                                                    flex-direction: column;
                                                    align-items: center;
                                                    justify-content: center;
                                                `}
                                            >
                                                <div
                                                    css={css`
                                                        display: flex;
                                                        flex-direction: column;
                                                        align-items: center;
                                                        justify-content: center;
                                                        text-align: center;
                                                        height: 100%;
                                                    `}
                                                >
                                                    <div
                                                        css={css`
                                                            font-family: pixel;
                                                            font-size: 2.5rem;
                                                            margin-bottom: 20px;
                                                        `}
                                                    >
                                                        NOW LOADING...
                                                    </div>
                                                    <StaticImage
                                                        src="../assets/images/loading-bar.png"
                                                        alt="loading..."
                                                        quality={100}
                                                    />
                                                </div>
                                            </RoadmapItem>
                                        )
                                    )
                                    .with(
                                        {
                                            type: 'notice',
                                        },
                                        () => (
                                            <div
                                                key={`roadmap-phase-${i}-${j}`}
                                                css={css`
                                                    font-style: normal;
                                                    font-weight: 400;
                                                    font-size: 1.125rem;
                                                    color: #747474;
                                                `}
                                            >
                                                * 위 로드맵은 커뮤니티 투표 및
                                                내부회의에 따라 변경될 수
                                                있습니다.
                                            </div>
                                        )
                                    )
                                    .exhaustive()
                            )}
                        </RoadmapItemWrapper>
                    ))}
                </div>
            </Container>
        </section>
    );
};

const Team: React.VFC<{
    image: React.ReactElement;
    name: string;
    role: string;
}> = ({ image, name, role }) => {
    return (
        <div
            css={css`
                text-align: center;
            `}
        >
            <div
                css={css`
                    margin-bottom: 40px;
                `}
            >
                {image}
            </div>
            <div
                css={css`
                    margin-bottom: 20px;
                    font-family: pixel, pixel-hangul;
                    font-size: 2rem;
                `}
            >
                {name}
            </div>
            <div
                css={css`
                    font-size: 1.375rem;
                `}
            >
                {role}
            </div>
        </div>
    );
};

const TeamSection: React.VFC = () => {
    const breakpoints = useBreakpoint();
    const scale = match(breakpoints)
        .with({ md: true }, () => 0.5)
        .otherwise(() => 1);

    return (
        <section
            css={css`
                margin-top: 170px;
                margin-bottom: 400px;
            `}
            id="team"
        >
            <Container>
                <SectionTitle
                    image={
                        <StaticImage
                            src="../assets/images/gear.png"
                            alt="Team"
                            width={70}
                            placeholder="none"
                            quality={100}
                        />
                    }
                    label="TEAM"
                />
                <div
                    css={css`
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: center;
                        column-gap: ${90 * scale}px;
                        row-gap: ${90 * scale}px;
                    `}
                >
                    <Team
                        image={
                            <StaticImage
                                src="../assets/images/team-0.png"
                                alt="VOX"
                                width={300}
                                placeholder="none"
                                quality={100}
                            />
                        }
                        name="복스"
                        role="Founder"
                    />
                    <Team
                        image={
                            <StaticImage
                                src="../assets/images/team-1.png"
                                alt="SUE.DEV"
                                width={300}
                                placeholder="none"
                            />
                        }
                        name="SUE.DEV"
                        role="Developer"
                    />
                    <Team
                        image={
                            <StaticImage
                                quality={100}
                                src="../assets/images/team-2.png"
                                alt="DAVID. KIM"
                                width={300}
                                placeholder="none"
                            />
                        }
                        name="DAVID. KIM"
                        role="Developer"
                    />
                    <Team
                        image={
                            <StaticImage
                                src="../assets/images/team-3.png"
                                alt="WIZARD"
                                width={300}
                                placeholder="none"
                                quality={100}
                            />
                        }
                        name="WIZARD"
                        role="CS / Marketing"
                    />
                    <Team
                        image={
                            <StaticImage
                                src="../assets/images/team-4.png"
                                alt="LEE"
                                width={300}
                                placeholder="none"
                                quality={100}
                            />
                        }
                        name="LEE"
                        role="Designer"
                    />
                </div>
            </Container>
        </section>
    );
};

const FAQItem: React.VFC<{
    question: string;
    answer: string;
}> = ({ question, answer }) => {
    const [opened, setOpened] = React.useState(false);
    return (
        <button
            onClick={() => {
                setOpened(!opened);
            }}
            css={css`
                display: block;
                width: 100%;

                padding: 0;
                background: none;
                border: none;
                cursor: pointer;
                display: block;
                border-top: 1px solid #fff;
                color: #fff;
                padding: 40px 0;

                text-align: left;
            `}
        >
            <div
                css={css`
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                `}
            >
                <div
                    css={css`
                        font-size: 1.5rem;
                        font-weight: bold;
                    `}
                >
                    {question}
                </div>
                <FontAwesomeIcon
                    icon={opened ? faMinus : faPlus}
                    css={css`
                        color: #42fff6;
                        font-size: 2rem;
                    `}
                />
            </div>
            <div
                css={css`
                    font-size: 1.25rem;
                    overflow: hidden;

                    transition: max-height 1s;
                    ${opened
                        ? css`
                              max-height: 200px;
                          `
                        : css`
                              max-height: 0;
                          `}
                `}
            >
                <div
                    css={css`
                        padding-top: 40px;
                    `}
                >
                    {answer}
                </div>
            </div>
        </button>
    );
};

const FAQSection: React.VFC = () => {
    return (
        <section id="faq">
            <Dither
                image={orangeDitherImage as string}
                color="#ff8340"
                direction="top"
                margin={120}
            ></Dither>
            <h1
                css={css`
                    margin: 20px 0;
                    transform: translateY(-50%);
                    text-align: center;
                    color: #fff;
                    font-family: pixel;
                    font-size: 4.5rem;
                `}
            >
                FAQ
            </h1>
            <div
                css={css`
                    background-color: #171717;
                    padding: 150px 0;
                `}
            >
                <Container>
                    <div
                        css={css`
                            border-bottom: 1px solid #fff;
                        `}
                    >
                        <h2
                            css={css`
                                font-size: 1.5rem;
                                font-weight: bold;
                                color: #fff;
                                margin-bottom: 45px;
                            `}
                        >
                            ADENA VOX는 무엇인가요?
                        </h2>
                        <p
                            css={css`
                                font-size: 1.375rem;
                                font-weight: normal;
                                line-height: 1.6;
                                color: #fff;
                                margin-bottom: 45px;
                            `}
                        >
                            ADENA VOX는 픽셀 2D로 이루어진 귀여운 인형같은
                            케릭터로, PFP로 쓰일뿐만 아니라 추후 P2E에
                            인형으로써 존재감을 드러내게 될 것입니다. 클레이튼
                            기반의 블록체인에서 생성되며 증명이 됩니다.
                        </p>
                        <FAQItem
                            question="민팅 날짜와 가격은 어떻게 되나요?"
                            answer="머지않아 프리세일과 Public이 곧 공개될 것입니다.
가격또한 날짜와 같이 공개되며, 일반 클레이튼 프로젝트 평균보다
낮은 가격으로 공개할 예정입니다. 시장에따라 달라질 수 있습니다."
                        />
                        <FAQItem
                            question="어떤 지갑을 이용하나요?"
                            answer="카이카스 지갑으로만 이용합니다."
                        />
                        <FAQItem
                            question="내 ADENA VOX를 어디에 사용할 수 있습니까?"
                            answer="ADENA VOX는 PFP 로써 역할뿐 아니라, 추후 P2E 게임 및 ADENA VOX팀과
                            연계되는 P2E 파트너십 프로젝트에 포털로 들어가 널리 사용되게 하는것이 목적입니다."
                        />
                        <FAQItem
                            question="민팅은 한번에 몇 개까지 가능한가요?"
                            answer="민팅은 최소 1개에서 최대 10개까지 가능할 것으로 보여지나, 이것도 OG나 WL 인원에따라
                            적절하게 분배될 수 있도록 추후 정확한 안내를 드릴 예정입니다."
                        />
                        <FAQItem
                            question="민팅은 어디서 하나요?"
                            answer="민팅은 ADENA VOX 사이트 자체내의 'MINTING' 페이지를 별도로 만들어 자체적으로
                            민팅을 진행할 예정입니다. 저희는 저희 자체 개발자가 준비되어 있습니다."
                        />
                    </div>
                </Container>
            </div>
        </section>
    );
};

const PartnersSection: React.VFC = () => {
    return (
        <section
            id="partners"
            css={css`
                padding: 100px 0 160px 0;
            `}
        >
            <SectionTitle
                image={
                    <StaticImage
                        src="../assets/images/handshake.png"
                        alt="PARTNERS"
                        width={200}
                        placeholder="none"
                        quality={100}
                    />
                }
                label="PARTNERS"
            ></SectionTitle>
            <div
                css={css`
                    text-align: center;
                    font-family: pixel;
                    font-size: 4.5rem;
                    color: #bababa;
                `}
            >
                SOON!
            </div>
        </section>
    );
};

const IndexPage = () => {
    const breakpoints = useBreakpoint();
    const absImageScale = match(breakpoints)
        .with(
            {
                sm: true,
            },
            () => 0.4
        )
        .with(
            {
                md: true,
            },
            () => 0.6
        )
        .otherwise(() => 1);

    return (
        <DefaultLayout>
            <TitleSection />
            <RollingCharacters />
            <AbsImage
                image={tenThousandNFTImage as string}
                width={1400 * absImageScale}
                left="0"
            />
            <AbsImage
                image={hangingBoxImage as string}
                width={200 * absImageScale}
                right={`${20 * absImageScale}%`}
                zIndex={10}
            />
            <StorySection />
            <AbsImage
                image={leftManufactureImage as string}
                width={500 * absImageScale}
                left="0"
                adjust={[
                    '0',
                    match(breakpoints)
                        .with({ sm: true }, () => '-120px')
                        .with({ md: true }, () => '-200px')
                        .otherwise(() => '-320px'),
                ]}
                zIndex={10}
            />
            <AbsImage
                image={rightManufactureImage as string}
                width={500 * absImageScale}
                right="0"
                adjust={[
                    '0',
                    match(breakpoints)
                        .with({ sm: true }, () => '-200px')
                        .with({ md: true }, () => '-320px')
                        .otherwise(() => '-420px'),
                ]}
                zIndex={10}
            />
            <AbsImage
                image={flyingVoxImage as string}
                width={700 * absImageScale}
                right="0"
                adjust={['100px', '400px']}
                zIndex={-1}
            />
            <RoadmapSection />
            <AbsImage
                image={yellowHeartImage as string}
                width={180 * absImageScale}
                left="200px"
                adjust={['0', '-360px']}
                zIndex={-1}
            />
            <TeamSection />
            <FAQSection />
            <PartnersSection />
            <RollingCharacters />
        </DefaultLayout>
    );
};

export default IndexPage;
